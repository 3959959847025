/**
 * **IMPORTANT**
 * This properties names are used by GTM triggers in order to track the events,
 * - if these names change the triggers in GTM must be updated as well,
 * - if u add new properties to this object, make sure to add them to the GTM triggers as well, or the data wont be tracked
 */
export enum AnalyticsEvent {
    NewUser = 'new_user',
    NewBooking = 'new_booking', // new confirmed Booking in booking status page
    NewBookingFailed = 'new_booking_failed', // new Booking failed status on Booking status page
    NewBookingIntent = 'new_booking_intent', // User clicked on "Book now" button, Pass price
    TripDealsViewed = 'trip_deals_viewed', // viewed trip deals
    ViewIndividualDeal = 'view_individual_deal', // User clicked on a deal
    ToggleToSMSSingIn = 'toggle_to_sms_sign_in', // User switched to "Sign in with SMS" Tab
    ViewSingInPrompt = 'view_sign_in_prompt', // View Sing in CTA
    SingInFromCTA = 'sign-from-cta', // Sing in from CTA

    // (Pending) - nice to have
    DropedOffFromSingIn = 'droped_off_from_sign_in', // User clicked on "Sign in" button
    ExpediaPercentOffShown = 'expedia_percent_off_shown', // Show red Expedia discount
    WhentToExpedia = 'went_to_expedia', // User clicked on Expedia link
}
